<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron partes de novedad"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idparte_novedad"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <parte-novedad-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <b10-fab-button
      @click="clickAdd"
    />
    <parte-novedad-list-legend
      :showing.sync="showingDialogs.legends"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import { get } from 'vuex-pathify'
import Data from './ParteNovedadListData'
import ParteNovedadListLegend from './components/ParteNovedadListLegend'
import ParteNovedadListItem from './components/ParteNovedadListItem'

const pageStoreName = 'pagesParteNovedadList'

export default {
  components: {
    ParteNovedadListLegend, ParteNovedadListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false
      },
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    idvigilante: get('usuario/idvigilante'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Partes de novedad'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectParteNovedad(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.idvigilante
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectParteNovedadRows(this, dirty.modified, this.idvigilante)
        await this.reloadItemsBase(dataset, dirty, 'idparte_novedad')
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'partesnovedad__parte-novedad-view',
        params: {
          idparte_novedad: item.data.idparte_novedad
        }
      })
    },
    clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: 'partesnovedad__parte-novedad-add'
      })
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
  },
}
</script>
