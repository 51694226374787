<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle>
        <v-chip
          v-if="formattedItem.estado_pendiente"
          small
          label
          dark
          color="red"
        >
          Pendiente
        </v-chip>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { toDate, isEqual } from '@/utils/date'
import { PARTE_NOVEDAD } from '@/utils/consts'
import colors from 'vuetify/lib/util/colors'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      if (isEqual(item.fparte, toDate(item.fparte))) {
        item.fechaParte = this.$options.filters.shortDate(item.fparte)
      } else {
        item.fechaParte = this.$options.filters.shortDateTime(item.fparte)
      }
      item.title = `Nº${item.idparte_novedad} - ${item.puesto_servicio_descripcion}`
      if (item.observaciones) {
        item.subtitle = `${item.fechaParte} - ${item.observaciones}`
      } else {
        item.subtitle = item.fechaParte
      }
      if (item.con_incidencias) {
        item.avatar = 'I'
        item.avatarColor = colors.red.base
      } else {
        item.avatar = 'N'
        item.avatarColor = colors.green.base
      }
      item.estado_pendiente = (item.estado === PARTE_NOVEDAD.estados.pendiente)
      return item
    }
  }
}
</script>
